import React, { Children, useEffect, useState } from "react";
import FadeIn from "react-fade-in";
import {
  CrossIcon,
  DeleteIcon,
  EditPencilIcon,
  EditPencilIcon2,
  EyeIcon,
  TableSortIconDownArrow,
} from "../../utils/icons/defaultIcons";
import TableTopBar from "../modal/tableTopBar";
import Table from "../../atom/table";
import Loader from "../loader";
import {
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import PaginationButtons from "../../atom/pagination";
import { Backdrop, CircularProgress } from "@mui/material";
import Notification from "../../atom/Notification";
import Lottiefy from "../../atom/lottie/lottie";
import * as loaderLottie from "../../utils/lottie/loaderLottie.json";
import {
  setBreadcrumbs,
  setPlanName,
} from "../../toolkit/reducers/breadCrumbsSlice";
import ComponentMap from "../../atom/componentmap";
import Dcid_Router from "./dcid_Router";
import { getDCIDPlans } from "../../services/api";

const Dcid_Management = () => {
  const [manageState, setManageState] = useState<string>("");
  // const [tableData, setTableData] = useState<any>([]);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [pageCount, setPageCount] = useState<number>(0);
  const [perPage, setPerPage] = useState<number>(25);
  const [resultNotFound, setResultNotFound] = useState<boolean>(false);
  const [loader, setLoader] = useState<boolean>(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { uuid } = useParams();
  const [tableTitles, setTableTitles] = useState<any>([
    {
      key: "name",
      title: "DCID Plan Name",
      icon: <TableSortIconDownArrow />,
      sort: { direction: "asc", active: true },
    },
    {
      key: "total_dids",
      title: "Total DIDs",
      icon: <TableSortIconDownArrow />,
      sort: { direction: "asc", active: false },
    },
    {
      key: "action_button",
      title: "",
      type: "actionIcon",
      class: "text-right pr-[40px]",
      icon: <EyeIcon />,
      action: {
        title: "view",
        command: "view",
        action: "click",
      },
    },
    {
      key: "action_button",
      title: "",
      type: "actionIcon",
      class: "text-right pr-[40px]",
      icon: <DeleteIcon />,
      action: {
        title: "delete",
        command: "delete",
        action: "click",
      },
    },
  ]);

  const [tableData, setTableData] = useState<any>([]);

  const [searchtext, setSearchText] = useState<any>("");
  const [sort, setSort] = useState<any>({
    key: "name",
    order: "ASC",
  });
  const [handleKeyPress, setHandleKeyPress] = useState<boolean>(true);
  const [purchaseDidModal, setPurchaseDidModal] = useState<boolean>(false);

  const onHandlepurchase = () => {
    setPurchaseDidModal(true);
    navigate(`purchase`, { replace: false });
  };

  const path = useLocation();

  const handlePerPage = (value: any) => {
    value = parseInt(value);
    setPerPage(value);
    setPageNumber(1);
    onHandleFetch({ limit: value, pageNumber: 1 });
  };

  const onHandleFetch: any = (options: {
    limit?: number;
    searchtext?: string;
    pageNumber?: number;
    sort_column?: string;
    sort_direction?: string;
  }) => {
    console.log("");
    setLoader(true);
    getDCIDPlans({
      limit: options?.limit || perPage,
      pageId: options?.pageNumber || pageNumber,
      sort_column: options?.sort_column || sort.key,
      sort_direction: options?.sort_direction || sort.order,
      searchtext:
        options?.searchtext === undefined
          ? searchtext
          : options?.searchtext === ""
          ? ""
          : options?.searchtext,
    }).then((result: any) => {
      // console.log("data", result.data.items);
      const plans = result?.data?.items;
      if (!result.success || plans?.length === 0) {
        setLoader(false);
        setResultNotFound(true);
      } else {
        setResultNotFound(false);
      }
      setTableData(plans);
      setLoader(false);
      setPageCount(result?.data.total);
    });
  };

  const handleSearch = (data: string) => {
    setPageNumber(1);
    setSearchText(data);
    onHandleFetch({ searchtext: data, pageNumber: 1, limit: perPage });
  };

  const handleChange = (pNUm: number) => {
    list_DCID_Plans(pNUm);
    setPageNumber(pNUm);
  };

  const onHandleSort = (node: any, orderBy: string) => {
    setSort({ key: node.key, order: orderBy });
    onHandleFetch({ sort_column: node.key, sort_direction: orderBy });
  };

  const list_DCID_Plans = (pgNum: number) => {
    console.log("rechhed1");
    setPageNumber(pgNum);
    onHandleFetch({ pageNumber: pgNum });
  };

  // this function handles every button clicks for navigation :)
  const onhandleStateChange = (state: any, data: any) => {
    console.log("state1", state, "data", data);
    if (state === "New DCID Plan") {
      state = "newplan";
    }
    if (state === "view") {
      state = "viewplan";
      navigate(`${data?.uuid}/${state}`, { replace: false });
    }
    if (state !== "viewplan") {
      navigate(`${state}`, { replace: false });
    }
    if (state === "delete") {
      navigate(`${data?.uuid}/${state}`, { replace: false });
    }
    dispatch(setPlanName(data?.name));
  };

  const actionHandler = (action: string, data: any, index: number) => {
    console.log("action", action);
    const actionsMap: any = {
      view: onhandleStateChange,
      "New DCID Plan": onhandleStateChange,
      delete: onhandleStateChange,
    };
    const act = actionsMap[action];
    if (act) {
      act(action, data);
    } else {
      console.log("actionHandler Error");
    }
  };

  const activeBreadCrumbs = useSelector(
    (state: any) => state.BreadCrumbsList.brudCrubName
  );

  useEffect(() => {
    list_DCID_Plans(1);
    console.log("pathhh", path.pathname);
  }, [path.pathname]);

  return (
    <div>
      <div>
        <Dcid_Router />
      </div>
      <div className="h-full py-3  relative  rounded-md">
        <Backdrop className="!z-[99999999999]" open={loader}>
          <Lottiefy loop={true} json={loaderLottie} height={100} width={100} />
        </Backdrop>

        <div
          className={`h-[calc(100vh-125px)] ${
            purchaseDidModal && "basis-[65%]"
          }  overflow-x-auto overflow-y-hidden  rounded-md bg-[#fff]`}
        >
          <FadeIn>
            <div className="pt-3">
              <TableTopBar
                title={"DCID Management"}
                button={true}
                manageState={manageState}
                setManageState={setManageState}
                onhandleStateChange={onhandleStateChange}
                permission={"provision.users.post"}
                buttonText={"New DCID Plan"}
                search={true}
                searchtext={searchtext}
                setSearchText={setSearchText}
                handleSearch={handleSearch}
                handleKeyPress={handleKeyPress}
                setHandleKeyPress={setHandleKeyPress}
                actionHandler={actionHandler}
              />
            </div>

            <Table
              tableTitles={tableTitles}
              tableData={tableData}
              setTableData={setTableData}
              actionHandler={actionHandler}
              setTableTitles={setTableTitles}
              button={true}
              buttonIndex={4}
              buttonText1={"manage"}
              width={"w-[calc(100vw-122px)] overflow-auto"}
              scrollHeight={"h-[calc(100vh-280px)] overflow-y-auto"}
              manageState={manageState}
              onHandleSort={onHandleSort}
              setManageState={setManageState}
              onhandleStateChange={onhandleStateChange}
              permission={"provision.users.put"}
              actions={true}
              resultNotFound={resultNotFound}
            />
            {!resultNotFound && tableData.length > 0 && (
              <PaginationButtons
                pageNumber={pageNumber}
                handleChange={handleChange}
                pageCount={pageCount}
                perPage={perPage}
                handlePerPage={handlePerPage}
              />
            )}
          </FadeIn>
        </div>
      </div>
    </div>
  );
};

export default Dcid_Management;
