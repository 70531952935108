import React, { useState } from "react";
import Modal from "../../atom/modal";
import {
  Navigate,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { useSelector } from "react-redux";
import ThemedButton from "../../atom/ThemedButton/themedButton";
import { DeleteIcon } from "../../utils/icons/defaultIcons";
import { delete_DCID_Plan } from "../../services/api";
import Notification from "../../atom/Notification";

function Delete_Dcid_Modal() {
  const navigate = useNavigate();
  const path = useLocation();
  const { id }: any = useParams();
  const [loader, setLoader] = useState<boolean>(false);
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [buttonLoader, setButtonLoader] = useState<boolean>(false);

  const activeBreadCrumbs = useSelector(
    (state: any) => state.BreadCrumbsList.brudCrubName
  );
  var res = [...activeBreadCrumbs];

  const plan_Name = useSelector(
    (state: any) => state.BreadCrumbsList.dcid_PlanName
  );

  const onCloseModal = () => {
    setDeleteModal(false);
    navigate(-1);
  };
  const HandleDeletePlan = (data: any) => {
    setButtonLoader(true);
    console.log("datadata", data, "path", path);
    delete_DCID_Plan(id).then((result: any) => {
      if (!result.success) {
        setLoader(false);
        Notification(
          "Failed",
          `${
            result?.message
              ? result?.message
              : "Oops! Something went wrong. Please try again later"
          }`,
          "danger"
        );
        setButtonLoader(false);
      } else {
        Notification("Success", "Plan Deleted successfully", "success");
        setLoader(false);
        navigate(`/app/dcid`, { replace: true });
        setButtonLoader(false);
      }
    });
  };
  return (
    <Modal title={"Delete"} closeEvent={onCloseModal} icon={DeleteIcon()}>
      <div className="p-2">
        Are you you want to delete <b>{plan_Name}</b> ?
      </div>
      <div className="text-right w-full  mt-8">
        <ThemedButton
          children={"Cancel"}
          icon="custom"
          theme="secondary"
          click={onCloseModal}
        />
        <ThemedButton
          children={"Delete"}
          icon="custom"
          theme="primary"
          click={HandleDeletePlan}
          loading={buttonLoader}
        />
      </div>
    </Modal>
  );
}

export default Delete_Dcid_Modal;
